.project-container{
    font-family: "Poppins";
    min-height: 100vh;
    height: 100%;
    padding: 100px 0;
    background: #2d4059;
}

.project-heading{
    font-family: "DM Serif Display";
    margin-bottom: 90px;
    font-size: 55px;
    line-height: 1.1;
    text-align: center;
    color: #fff;
}




.project-row{
    display: flex;
    margin-top: 110px;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row-reverse;
    
}



.project-img-column{
    margin-bottom: 15px;
    margin-left: -50px;
    padding-left: 15px;
    padding-right: 15px;
    flex: 1;
    z-index: 10;
    max-width: 50%;
    flex-basis: 50%;
}

.img-wrap{
    max-width: 800px;
    display: flex;
    justify-content: flex-end;   
}

.project-img{
    padding-right: 0;
    border: 0;
    max-width: 100%;
    max-height: 390px;
    vertical-align: middle;
    display: inline-block;
    max-height: 900px;
    box-shadow: 0 15px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
}



.project-column{
    margin-bottom: 15px;
    margin-left: 50px;
    padding-left: 15px;
    padding-right: 15px;
    flex: 1;
    max-width: 50%;
    flex-basis: 50%;  
}

.card{
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
   
}


.card-title{
    font-size: 1.6rem;
}

.proj-desc{
    width: 90%;
}

.techList-wrap{
    
        display: flex;
        
}
.techList-container{

    width: 70%;  
    background-color: #ffa62b;
    padding: 10px 0px 10px 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 0.9rem;
    
}


.ul-wrap{
    margin-bottom: 0;
    color: #2d4059;
    display: flex;
    flex-wrap: wrap;
}

.technologies{
    display: inline;
    margin-right: 30px;
    
    
}

.btn-wrap{
    display: flex;
}


.btn-site{
    background-color: #ea5455;
    border: none;

}

.btn-github{
    background-color: #838991;
    border: none;    
}

.btn-github:hover{
    background-color: #45474b;
}



.btn-icon{
    margin-right: 5px;
}

.project-btn{
    margin: 20px 5px 0px 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
 
 }


@media screen and (max-width: 971px){
    .project-column{
        max-width: 90%;
        flex-basis: 100%;
        display: inline;
        justify-content: center;
        margin: 0 auto;
    }

    .btn-wrap{
       text-align: center;
       justify-content: center;
    }

    .techList-container{
        margin: 0 auto; 
        
    }


    .project-row{
        margin-top: 40px;

    }

    .project-heading{

        margin-bottom: 10px;

    }
    

    .ul-wrap{
        margin: 0 auto; 
        justify-content: center;

    }


    .project-img-column{
        max-width: 90%;
        flex-basis: 100%;
        display: flex;
        justify-content: center;
        margin: 20px auto;
    }


} 








