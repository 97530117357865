

@media screen and (min-width: 971px){
    .alt-row{
        flex-direction: row;
       
    }
    
    .alt-img-column{
        margin-left: 50px;
    }
    
    .alt-column{
        margin-left: -50px;
    }
    
    .alt-card{
        text-align: right;
    }
    
    .alt-desc{
        margin-right: -60px;
    }
    
    .alt-tech-wrap{
    justify-content: flex-end;
    }
    
    .alt-btn{
        justify-content: flex-end;
    }

    .alt-ul{
        justify-content: flex-end;
    }
    

}

@media screen and (min-width: 1175px){
    .alt-desc{
        margin-right: -70px;
    }
}