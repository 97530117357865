@import "./hero.css";
@import "./about.css";
@import "./contact.css";
@import "./project.css";
@import "./projectAlt.css";

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
}